@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css';
@import './normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;600;800;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

*,
html,
body,
#root {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.svg {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1748' height='1748' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23759fff' stroke-width='70.2' stroke-linecap='round' %3E%3Cline stroke='%239de6dd' x1='639' y1='727' x2='590' y2='776'/%3E%3Cpolyline stroke='%2385aee9' points='586 717 888 415 981 512 279 1215 120 1056 607 569'/%3E%3Cline stroke='%2361b1ff' x1='935' y1='494' x2='776' y2='653'/%3E%3Cline stroke='%234fa7e9' x1='425' y1='624' x2='488' y2='560'/%3E%3Cpolyline stroke='%2352baf5' points='745 685 198 1232 -24 1010 226 759'/%3E%3Cline stroke='%2363bee8' x1='383' y1='666' x2='393' y2='655'/%3E%3Cline stroke='%234ab7e0' x1='416' y1='569' x2='438' y2='547'/%3E%3Cline stroke='%2344c7f2' x1='194' y1='664' x2='237' y2='622'/%3E%3Cpolyline stroke='%236dd9f8' points='374 547 35 886 194 1045 618 622'/%3E%3Cline stroke='%2368d8f4' x1='681' y1='685' x2='671' y2='696'/%3E%3Cline stroke='%236cdbf4' x1='724' y1='516' x2='649' y2='590'/%3E%3Cline stroke='%2369cddf' x1='334' y1='776' x2='501' y2='611'/%3E%3Cpolyline stroke='%238beaf6' points='556 746 226 1077 353 1204 1052 505 956 410 713 653'/%3E%3Cline stroke='%2368a8ad' x1='258' y1='727' x2='385' y2='600'/%3E%3Cline stroke='%2392eeef' x1='671' y1='505' x2='639' y2='537'/%3E%3Cpolyline stroke='%23a7e0dc' points='162 696-24 883 71 978 353 696'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.nav-item {
  color: inherit;
}

.bg-gradient {
  background: linear-gradient(
    90deg,
    rgba(83, 158, 208, 1) 15%,
    rgba(75, 151, 202, 1) 33%,
    rgba(0, 81, 145, 1) 100%
  );
}

.bg-image {
  background-repeat: no-repeat;
  background-position: bottom;
}

.fill-screen {
  height: 100vh;
  width: 100vw;
}

.general-container {
  min-height: 100vh;
  width: 100%;
  min-width: 320px;
  max-width: 100vw;
  text-align: center;
  padding: 0.3em 1em;
  padding-left: 55px;
  background-repeat: no-repeat;
  background-position: bottom center;
}

@media (max-width: 879px) {
  .general-container {
    width: 100%;
    padding-bottom: 5em;
    padding-left: 1em;
  }
}

.generic-card {
  padding: 2.5%;
  background-color: rgb(255 255 255 / 0.9);
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
  width: auto;
  height: fit-content;
}

a,
a:visited {
  color: inherit;
}
.full-height {
  min-height: 100vh;
}

.img-md {
  width: 10em;
}

.img-lg {
  width: 15em;
  max-width: 100%;
  max-height: 100%;
}

.centered-img {
  position: fixed;
  top: calc(50% - 7.5em);
  left: calc(50% - 7.5em);
}
.full-width {
  width: 100%;
  max-width: 100%;
}
.grid-center {
  place-items: center;
}
.icon-md {
  width: 10em;
  height: auto;
  max-width: 30vw;
}
.txt-grey {
  color: grey;
}

.bg-green {
  background: #066;
}
.bold {
  font-weight: bolder;
}

.txt-underline {
  text-decoration: underline;
}

.meta-txt {
  color: #808080e0;
  font-style: italic;
}

.full {
  width: 100%;
}

.full-row {
  grid-column: span 2;
}

.pointer {
  cursor: pointer;
}

.center-margin {
  margin: auto;
}
.justify-start {
  justify-self: start;
}

.justify-end {
  justify-self: end;
}

.justify-center {
  justify-self: center;
}

.verticalFill {
  flex: 1;
}

.txt-red {
  color: red;
}

.txt-green {
  color: #066;
}

.txt-md {
  font-size: 2em;
  padding: 0.3em;
}
.txt-xl {
  font-size: 15em;
  padding: 10px;
}

.grid-main {
  display: grid;
  grid-template-columns: auto 1fr;
}

.show {
}
.flex-center {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}
.hide {
  position: absolute;
  left: -230px;

  transition: 300ms ease-out;
}
.full {
}

.center {
  display: block;
  margin: auto;
}

.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.centered {
  display: block;
  margin: auto;
}

.selected {
  color: red !important;
}

.btn-round {
  border: 0;
  border-radius: 50%;
}
.capitalize {
  text-transform: capitalize;
}

.txt-italic {
  font-style: italic;
}

.active-tab {
  border-bottom: 0.2em solid green;
}

.gradient-heading {
  background: -webkit-linear-gradient(-150deg, #4fc3f7, dodgerblue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  line-height: 1;
  font-size: 3em;
  text-transform: uppercase;
  color: #a483c5;
  font-family: arial;
}

.top {
  position: absolute;
  top: 1em;
  right: 1em;
  font-weight: bold;
}

.login-form-container {
  margin: auto;
  height: 80%;
  width: min-content;
  margin-top: 3%;
}

.auth-form {
  width: 75%;
  margin: auto;
  text-align: center;

  position: relative;
  top: -44%;
}

.signup-form {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.signup-form > input {
  height: 2.25em !important;
}

.add-input {
  width: 90% !important;
  margin: 1% 0 !important;
  padding-left: 1% !important;
  background: white !important;
  border: 2px solid rgb(121, 121, 219) !important;
  border-radius: 0.5em !important;
  transition: box-shadow 500ms !important;
  font-size: 125% !important;
}

.add-input:hover {
  box-shadow: 2px 2px 5px 1px gray !important;
}

.general-heading {
  color: #005191;
  font-size: 1.75em;
  font-weight: bold;
  text-align: center;
  font-family: 'Roboto Condensed', sans-serif;
}

.red-button {
  background-color: #9c1313;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.35em;

  width: fit-content;
  width: -moz-fit-content;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;

  cursor: pointer;
}

.yellow-button {
  background-color: #fcb23d;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.35em;

  width: fit-content;
  width: -moz-fit-content;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;

  cursor: pointer;
}

.blue-button {
  background-color: #005191;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.35em;

  width: fit-content;
  width: -moz-fit-content;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;

  cursor: pointer;
}

.yellow-button:disabled {
  background-color: rgb(255 255 255 / 0.9);
  color: #005191;
  cursor: not-allowed;
  border: 1px solid #005291b0;
}

/* Added by Ashish */

.general-font {
  font-family: 'Roboto';
}

.bold-font {
  font-family: 'Roboto Condensed';
}

.white-text {
  color: white !important;
}
.blue-text-light {
  color: #2196f3 !important;
}

.blue-text-dark {
  color: #005191 !important;
}

.uppercase {
  text-transform: uppercase;
}

.desc-title {
  text-align: center;
  font-size: 1.5em;
  font-family: 'Roboto Condensed', sans-serif;
}

#customModal {
  display: block;
  width: fit-content;
  padding: 2%;
  margin-top: 25vh;
  border-radius: 5px;
  min-width: 35vw;
}

#customModal > .content {
  margin-top: 1%;
  margin-bottom: 2%;
}
